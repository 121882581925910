<template>
    <main>
     <div class="alert alert-primary shadow-none rounded-lg p-2 m-1">
            <i class="fa fa-info-circle fa-fw text-primary animate__animated animate__heartBeat animate__infinite"></i>
            <strong>Víctima del conflicto armado.</strong>
        </div>
    </main>
</template>
<script>
import "animate.css"
export default {
  name: 'VictimArmedConflict',
}
</script>
